<mat-dialog-content>
    <p>{{message}}</p>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Cancel Message</mat-label>
      <textarea matInput [(ngModel)]="cancelMessage" required rows="4"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close tabindex="-1">
      {{cancelButtonText}}
    </button>
    <button mat-raised-button color="primary" [disabled]="!cancelMessage" (click)="onConfirmClick()" tabindex="1">
      {{confirmButtonText}}
    </button>
  </mat-dialog-actions>