

<div class="h-screen flex items-center justify-center">
  <div class="bg-white rounded-lg shadow-lg w-full lg:w-1/3 p-6">
    <div class="flex flex-col items-center justify-center p-8">
      <img
        src="assets/images/png/logo.png"
        alt="Buslane Logo"
        class="w-auto my-4"
      />
      <span class="font-medium text-center text-3xl my-4">Reset your password</span>
    </div>
    <div class="m33">
      <div class="modal-body">
        <p>Enter your email and we'll send you a link to reset your password.</p>
        <form class="modal-form" [formGroup]="resetForm">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width ffw"
          >
            <p class="point__label">Email</p>
            <div class="input-container">
              <input
                matInput
                placeholder="Enter your email"
                formControlName="email"
                aria-label="State"
                onkeypress="return event.charCode !== 13"
                (keydown)="preventSpaceBar($event)"
              />
            </div>
          </mat-form-field>
          <span
            class="text-danger"
            *ngIf="
              resetForm.get('email')?.touched &&
              resetForm.get('email')?.errors?.required
            "
            >This field is required.</span
          >
          <span
            class="text-danger"
            *ngIf="
              resetForm.get('email')?.touched &&
              resetForm.get('email')?.errors?.email
            "
            >Please enter a valid email address.</span
          >
          <div class="w-full mt-3">
            <button mat-flat-button color="primary" class="w-full" (click)="handleReset()">Reset My Password</button>
          </div>
        </form>
      </div>
    </div>
    
  </div>
</div>
