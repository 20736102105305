
import { Injectable } from '@angular/core';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import * as _ from 'lodash';
import { ACL } from '@app/helpers/acl';
// import { AppRole } from '@app/pages/auth/shared/interfaces/auth.interface';

@Injectable({
  providedIn: 'root'
})
/**
 * Acl service to check user permission.
 */
export class AclService {

  constructor(private authService: AuthService) {
  }

  /**
   * Check if current user have permission in params.
   * @param permission string   Permission to check.
   * @return boolean    True if has permission.
   */
  can(permission: string): boolean {
    const currentUser = this.authService.authValue;
    const roles = ACL[permission];

    if (!roles) {
      return false;
    }
    if (_.indexOf(roles, '*') >= 0) {
      return true;
    }
    return roles?.includes(currentUser?.roles);
  }

  /**
   * Check current user is one of argument roles.
   * @param roles string[]     Role list
   * @return boolean      True if is one of roles.
   */
  isRole(roles: string[]): boolean {
    const regexRoles = new RegExp(roles.join('|'), 'i');
    const currentUser = this.authService.authValue;
    return regexRoles.test(currentUser.roles);
  }
}