import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUser } from '@app/pages/operator-settings/users/shared/interfaces/user.interface';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private users$: BehaviorSubject<IUser>;
  private users: Observable<IUser>;

  constructor(
    private http: HttpClient,
    private userAuthenticationService: AuthService
  ) {
    this.users$ = new BehaviorSubject<IUser>(null);
    this.users = this.users$.asObservable();
  }

  getUsers() {
    const { operator_id } = this.userAuthenticationService.authValue;
    return this.http
      .post<IUser>(
        `${environment.apiUrl}/api/v1/operators/${operator_id}/user-list`,
        {}
      )
      .pipe(
        map((response: any) => {
          // id, first_name, last_name, roles, is_active
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNotifications() {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/v1/operators/user-notifications`,
        {}
      )
  }

  addNotifications(id: number, notificationId: number) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/v1/operators/${id}/add-notifications`,
        { user_notification_id: notificationId }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  removeNotifications(id: number, notificationId: number) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/v1/operators/${id}/remove-notifications`,
        { user_notification_id: notificationId }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  deleteOperatorUser(id: number) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/api/v1/operators/${id}/delete-operator-user`,
        {}
      )
      .pipe(
        map((response: any) => {
          if(response) {
            return response
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  updateUser(id: number, data: any) {
    // {{base_url}}{{path}}operators/user-list/user_id/update
    return this.http
      .post<IUser>(
        `${environment.apiUrl}/api/v1/operators/user-list/${id}/update`,
        { ...data }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            this.users$.next(response.data);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  createUser(data: any) {
    // add default password {{base_url}}{{path}}/operators/users/create
    const { operator_id } = this.userAuthenticationService.authValue;

    const newData = {
      ...data,
      name: data.first_name.concat(` ${data.last_name}`),
      access_token: null,
      secret: null,
      address_1: null,
      address_2: null,
      zipcode: null,
      city: null,
      state: null,
      is_operator: true,
      phone: '222222',
      password: '12345678',
      operator_id,
    };

    console.log(newData);

    return this.http
      .post<IUser>(`${environment.apiUrl}/api/v1/operators/users/create`, {
        ...newData,
      })
      .pipe(
        map((response: any) => {
          if (response) {
            this.users$.next(response.data);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  // updateOperatorProfile(profile: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http.post(`${environment.apiUrl}/api/v1/operators/update/`, profile).subscribe((dt: any) => {

  //       return true;
  //     }, (error: any) => { console.log(error); reject(false) });

  //   });
  // }

  getOperator(id: string, userId?: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.apiUrl}/api/v1/operators/${id}`,
          userId ? { operator_user_id: userId } : {}
        )
        .subscribe(
          (d: any) => {
            resolve(d);
          },
          (err: any) => reject(err)
        );
    });
  }

  updateOperator(id: string, data: any) {
    return this.http
      .post(`${environment.apiUrl}/api/v1/operators/update/${id}`, data)
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }
}
