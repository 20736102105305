import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-itinerary-modal',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  continue: boolean = false;
  public resetForm: FormGroup;
  token: string | any = null;
  email: any;
  ngOnInit(): void {
    this.resetForm = this.formBuilder.group(
      {
        code: ['', [Validators.required]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.activeRoute.queryParams.subscribe((res: any) => {
      this.token = res.token;
    });

    this.email = this.route.snapshot.queryParams['email'];
  }

  passwordMatchValidator(form: FormGroup) {
    let str = form.controls['password'].value;
    if (
      form.controls['password'].value === form.controls['confirmPassword'].value
    ) {
      return null;
    } else if (!(/\d/.test(str) && /[a-zA-Z]/.test(str)) || form.controls['password'].value.length < 7) {
      form.controls['password'].setErrors({ weak: true });
      return { weak: true };
    } else {
      form.controls['confirmPassword'].setErrors({ mismatch: true });
      return { mismatch: true };
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  hasNumber(str: any) {
    return /\d/.test(str);
  }

  containsAnyLetters(str: any) {
    return /[a-zA-Z]/.test(str);
  }

  navigateTo(address: string) {
    this.router.navigate([address]);
  }

  handleReset() {
    if (this.resetForm.valid) {
      this.authService
      .resetPassword(
        this.email,
        this.resetForm.value['password'],
        this.resetForm.value['code']
      )
      .subscribe(
        (data) => {
          this._snackBar.open('Password has been set successfully.', 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
          setTimeout(() => {
            this.router.navigate(['/auth/signin']);
          }, 1000);
        },
        (error) => {
          this._snackBar.open('Please check your input', 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        }
      );
    }
    else{
      console.log(this.resetForm.get('password').errors)
    }
  }
}
