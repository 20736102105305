import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './layouts/admin/admin.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthModule } from './pages/auth/auth.module';
import { SharedModule } from '@shared/shared.module';
import { HasRoleDirective } from '@app/helpers/directives/has-role.directive';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StripeComponent } from '@app/pages/stripe/stripe.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@environments/environment';
import { ConfirmDialogComponent } from './pages/operator-settings/users/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

const config: SocketIoConfig = {
  url: environment.apiRoute, options: {
    transports: ['websocket'],
    query: {
      "system": "OP"
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BlankComponent,
    HasRoleDirective,
    StripeComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AuthModule,
    NgxMaskModule.forRoot(),
    // SocketIoModule.forRoot(config)
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
