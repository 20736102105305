import {Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.scss']
})
export class RefundModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  @Output() emitService = new EventEmitter();

  ngOnInit(): void {
    this.total()
  }

  toggleDialog(status: any) {
    this.emitService.next(status);
  }
  total(){
    return Number(this.data.baseCharge) + Number(this.data.bookingFee) + Number(this.data.financeFee)
  }
}
