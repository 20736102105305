<div class="h-screen flex items-center justify-center">
  <div class="bg-white rounded-lg shadow-lg w-full lg:w-1/3 p-6">
    <div class="flex flex-col items-center justify-center p-8">
      <img
        src="assets/images/png/logo.png"
        alt="Buslane Logo"
        class="w-auto my-4"
      />
      <span class="font-medium text-center text-3xl my-4">Operator Portal</span>
    </div>
    <div class="flex flex-col items-center p-8">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Username</mat-label>
          <input
            matInput
            formControlName="email"
            type="text"
            name="email"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            type="password"
            name="password"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>
        <a class="forgot-link" [routerLink]="['/forgot-password']">Forgot Password?</a>

        <div class="w-full">
          <button mat-flat-button color="primary" class="w-full" [disabled]="loading">Log In</button>
        </div>


        <div class="w-full mt-8">
          <p class="text-center text-sm">
            By continuing, you agree to Buslane's Conditions of Use and Privacy
            Notice
          </p>

          <a mat-stroked-button [routerLink]="['/auth/signup']" class="w-full">
            Don't have an account? Sign up here
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
