import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-decline-quote',
  templateUrl: './decline-quote.component.html',
  styleUrls: ['./decline-quote.component.scss']
})
export class DeclineBookingComponent implements OnInit {
  message: string = "Are you sure?";
  confirmButtonText = "Yes";
  cancelButtonText = "Cancel";
  reasons = [
    "Expired",
    "Price",
    "Not Ready to Book",
    "Sold Out",
    "No Driver Available",
    "Bus Out of Service",
    "Other"
  ];
  selectedReason: string = "";
  otherReason: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialog: MatDialogRef<DeclineBookingComponent>
  ) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  ngOnInit(): void {}

  onConfirmClick(): void {
    const declineReason = this.selectedReason === "Other" ? this.otherReason : this.selectedReason;
    this.dialog.close({ confirmed: true, reason: declineReason });
  }

  showOtherReason(): boolean {
    return this.selectedReason === "Other";
  }
}
