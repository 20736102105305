<mat-dialog-content class="mat-typography text-center" style="display: inline;">
  <img class="preview_img" [src]="profileImage ? profileImage : placeHolder" />

  <div class="upload-img-profile">
    <label for="profile_image"></label>
    <p>
      <input type="file" accept="image/png, image/jpg, image/jpeg" name="image_avatar" class="image_avatar" id="file" (change)="handleFileInput($event)" />
    </p>
    <label for="file" class="btn-choose-img">Choose Image</label>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="toggleDialog(false)">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" [disabled]="!profileImage" (click)="toggleDialog(true)">Upload</button>
</mat-dialog-actions>
