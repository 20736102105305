<mat-dialog-content class="mat-typography text-center" >
  <div class="relative p-5 overflow-hidden">
    <h2 class="leading-normal" style="font-size: 12px;" *ngIf="!data.isChargePopup">You will charge the customer ${{ data?.quoteData?.quote?.totalPrice }}. Are you sure?</h2>
   
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="toggleDialog(false)">No</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="toggleDialog(true)">Yes</button>
</mat-dialog-actions>
