import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { IQuote } from '@app/pages/dashboard/shared/interfaces/quote.interface';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private quoteDetail$: BehaviorSubject<IQuote>;
  public quoteDetail: Observable<IQuote>;

  constructor(private http: HttpClient) {
    this.quoteDetail$ = new BehaviorSubject<IQuote>(null);
    this.quoteDetail = this.quoteDetail$.asObservable();
  }

  loadBuslaneImage(item: any) {
    return new Promise((resolve, reject) => {
      if (null == item.img_url) {
        this.http
          .post(
            `${environment.apiUrl}/api/v1/vehicles/vehicle_image/${item.vehicle_name}`,
            {}
          )
          .subscribe(
            (dt: any) => {
              resolve(dt);
            },
            (error: any) => {
              reject(error);
            }
          );
      }
    });
  }
  toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }
  fromBinary(binary) {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const charCodes = new Uint16Array(bytes.buffer);
    let result = '';
    for (let i = 0; i < charCodes.length; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  }

  encodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    // return decodeURIComponent(escape(atob(str)));
    return btoa(this.toBinary(str));
  }

  decodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    const decoded = atob(str);
    return this.fromBinary(decoded);
  }

  loadSessionN2(sessionId) {
    return new Promise((resolve, reject) => {
      const url = `${environment.apiUrl}/api/v1/users/session/${sessionId}`;

      this.http.post(url, {}).subscribe(
        (result: any) => {
          let r = result; // JSON.parse(result);

          const dt = JSON.parse(this.decodeUnicode(r['data']));

          let dt2 = JSON.parse(dt['data']).sort((a, b) => a.idx - b.idx);

          sessionStorage.setItem(`final_values_2`, JSON.stringify(dt2));
          sessionStorage.setItem(`editFront`, '1');
          sessionStorage.setItem(`noofpassengers_2`, dt['passenger']);
          sessionStorage.setItem(`user_session_2`, sessionId);
          if (undefined !== dt['duration']) {
            sessionStorage.setItem('final_duration_2', dt['duration']);
          }
          if (undefined !== dt['vehicle_qty']) {
            sessionStorage.setItem(`vehicle_qty_2`, dt['vehicle_qty']);
          }

          if (undefined !== dt['extra_data']) {
            sessionStorage.setItem(`extra_data_2`, dt['extra_data']);
          }
          if (undefined !== dt['operatorArray']) {
            sessionStorage.setItem(`operatorArray_2`, dt['operatorArray']);
          }

          if (undefined !== dt['amenitiesSelectedRes']) {
            sessionStorage.setItem(
              `amenitiesSelectedRes_2`,
              dt['amenitiesSelectedRes']
            );
          }

          if (undefined !== dt['locationByDay']) {
            sessionStorage.setItem(`locationByDay_2`, dt['locationByDay']);
          }

          if (undefined !== dt['amenitiesRes']) {
            sessionStorage.setItem(`amenitiesRes_2`, dt['amenitiesRes']);
          }

          if (undefined !== dt['vehicleQuoteRes']) {
            sessionStorage.setItem(`vehicleQuoteRes_2`, dt['vehicleQuoteRes']);
          }

          if (undefined !== dt['trip_timestamps']) {
            sessionStorage.setItem(`trip_timestamps_2`, dt['trip_timestamps']);
          }
          if (undefined !== dt['totalVehicle']) {
            sessionStorage.setItem(`totalVehicle_2`, dt['totalVehicle']);
          }
          if (undefined !== dt['formData']) {
            sessionStorage.setItem(`formData_2`, dt['formData']);
          }

          if (undefined !== dt['totalExtra']) {
            sessionStorage.setItem(`totalExtra_2`, dt[`totalExtra`]);
          }

          if (undefined !== dt['times']) {
            sessionStorage.setItem(`times_2`, dt[`times`]);
          }

          if (undefined !== dt['usrinfo']) {
            sessionStorage.setItem(`usrinfo_2`, dt[`usrinfo`]);
          }
          if (undefined !== dt['priceDetail']) {
            sessionStorage.setItem(`priceDetail_2`, dt[`priceDetail`]);
          }
          if (undefined !== dt['before_payment']) {
            sessionStorage.setItem(`before_payment_2`, dt[`before_payment`]);
          }
          if (undefined !== dt['priceDetail']) {
            sessionStorage.setItem(`priceDetail_2`, dt[`priceDetail`]);
          }
          if (undefined !== dt['fixedPriceDetail']) {
            sessionStorage.setItem(
              `fixedPriceDetail_2`,
              dt[`fixedPriceDetail`]
            );
          }

          if (undefined !== dt['list_dead_head']) {
            sessionStorage.setItem(`list_dead_head_2`, dt[`list_dead_head`]);
          }
          if (undefined !== dt['final_duration_ms']) {
            sessionStorage.setItem(
              `final_duration_ms_2`,
              dt[`final_duration_ms`]
            );
          }

          if (undefined !== dt['total_distance']) {
            sessionStorage.setItem(`total_distance_2`, dt[`total_distance`]);
          }

          if (undefined !== dt['stops_after']) {
            sessionStorage.setItem(`stops_after2`, dt[`stops_after`]);
          }
          if (undefined !== dt['lsIds']) {
            sessionStorage.setItem(`lsIds2`, dt[`lsIds`]);
          }

          if (undefined !== dt['final_values']) {
            sessionStorage.setItem(`final_values2`, dt[`final_values`]);
          }

          resolve({ status: 1 });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getDetail(quoteId: number) {
    return this.http
      .post<IQuote>(`${environment.apiUrl}/api/v1/quotes/${quoteId}`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.quoteDetail$.next(response);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  getDetail3(quoteId: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/v1/quotes/${quoteId}`,
      {}
    );
    // return new Promise((resolve, reject) => {
    //   this.http.post(``, {})
    //     .pipe(
    //       map((response: any) => {
    //         if (response) {
    //           this.quoteDetail$.next(response);
    //           return response;
    //         }

    //         throw new Error(response.errorMessage);
    //       })
    //     )
    //     .subscribe(
    //       (e: any) => {
    //         // const sessionId = e.user_session;
    //         // this.loadSessionN2(sessionId).then(
    //         //   (r: any) =>
    //         //     resolve({
    //         //       status: 'loaded',
    //         //     }),
    //         //   (error: any) => reject(error)
    //         // );
    //       },
    //       (error) => reject(error)
    //     );
    // });
  }

  private model = 'quotes';
  private paymentModel = 'payment';

  changeQuoteStatus(id: number | string, status: string) {
    const endPoint = `${environment.apiRoute}/${this.model}/update-quote-status`;
    return this.http.post<any>(endPoint, {
      quoteId: id,
      quoteStatus: status,
    });
  }

  getQoutePayment(body: { id: number | string }) {
    const endPoint = `${environment.apiRoute}/${this.model}/get-quote-payment-details`;
    return this.http.post<any>(endPoint, body);
  }

  chargeQuotePayment(body: {
    quoteId: number;
    amount: number;
    chargeReason: string;
  }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/additional-charge-quote`;
    return this.http.post<any>(endPoint, body);
  }

  refundQoutePayment(body: {
    paymentId: number;
    charge: string;
    amount: number;
    refundReason: string;
    operatorId: string;
  }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/refund-quote`;
    return this.http.post<any>(endPoint, body);
  }

  createLinkToken(body: { operatorId: string }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/create-operator-link-token`;
    return this.http.post<any>(endPoint, body);
  }

  addACH(body: { operatorId: string; accountId: string; publicToken: string }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/add-operator-ach`;
    return this.http.post<any>(endPoint, body);
  }

  createExpressAccount(body: any) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/create-express-account`;
    return this.http.post<any>(endPoint, body);
  }

  retriveExpressAccount(body: any) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/retrieve-express-account`;
    return this.http.post<any>(endPoint, body);
  }

  retrieveStandardAccount(body: any): any {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/retrieve-standard-account`;
    return this.http.post<any>(endPoint, body);
  }

  deleteACH(body: { operatorId: string }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/delete-operator-ach`;
    return this.http.post<any>(endPoint, body);
  }

  retriveACH(body: { operatorId: string }) {
    const endPoint = `${environment.apiRoute}/${this.paymentModel}/retrieve-operator-ach`;
    return this.http.post<any>(endPoint, body);
  }

  getDetail2(quoteId: number) {
    return new Promise((resolve, reject) => {
      this.http
        .post<IQuote>(`${environment.apiUrl}/api/v1/quotes/${quoteId}`, {})
        .pipe(
          map((response: any) => {
            if (response) {
              this.quoteDetail$.next(response);
              return response;
            }

            throw new Error(response.errorMessage);
          })
        )
        .subscribe((e: any) => {
          let _r = `${environment.apiUrl}/api/v1/quotes/trips/${e.quote_id}`;
          setTimeout(() => {
            this.http
              .post(
                `${environment.apiUrl}/api/v1/quotes/trips/${e.quote_id}`,
                {}
              )
              .subscribe(
                (e2: any) => {
                  let r = e2[0];

                  sessionStorage.setItem(`data_detail_2`, JSON.stringify(r));

                  const dt = JSON.parse(atob(r['data']));

                  let dt2 = JSON.parse(dt['data']).sort(
                    (a, b) => a.idx - b.idx
                  );

                  sessionStorage.setItem(`final_values_2`, JSON.stringify(dt2));
                  sessionStorage.setItem(`editFront_2`, '1');
                  sessionStorage.setItem(`noofpassengers_2`, dt['passenger']);
                  sessionStorage.setItem(`user_session_2`, r['user_session']);
                  if (undefined !== dt['vehicle_qty_2']) {
                    sessionStorage.setItem(`vehicle_qty_2`, dt['vehicle_qty']);
                  }
                  if (undefined !== dt['extra_data_2']) {
                    sessionStorage.setItem(`extra_data_2`, dt['extra_data']);
                  }

                  if (undefined !== dt['operatorArray_2']) {
                    sessionStorage.setItem(
                      `operatorArray_2`,
                      dt['operatorArray']
                    );
                  }
                  this.loadSessionN2(e.user_session).then((r) =>
                    resolve({ status: 'loaded' })
                  );
                  // this.loadSessionParams2().then((l2) => {

                  //   this.loadSessionParams3().then((l3) => {
                  //     this.loadSessionParams4().then((l4) => {
                  //       resolve({
                  //         status: "loaded"
                  //       });
                  //     });

                  //   });
                  // });
                },
                (error: any) => {
                  console.log({ error });
                }
              );
          }, 1000);
        });
    });
  }

  loadSessionParams2() {
    return new Promise((resolve, reject) => {
      let context = {
        action: 'buslane_get_user_session',
        sessionId: sessionStorage.getItem('user_session_2') + '_checkout',
      };
      this.http
        .post(
          `${environment.apiUrl}/api/v1/users/session/${context.sessionId}`,
          {}
        )
        .subscribe((result: any) => {
          let r = result; //JSON.parse(result);
          if (r !== null) {
            const dt = JSON.parse(atob(r['data']));
            sessionStorage.setItem(`before_payment_2`, JSON.stringify(dt));

            resolve(dt);
          } else {
            // resolve({
            //     status: "next"
            // });
          }
        });
    });
  }

  loadSessionParams3() {
    return new Promise((resolve, reject) => {
      let context = {
        action: 'buslane_get_user_session',
        sessionId: sessionStorage.getItem('user_session_2') + '_checkout_1',
      };

      this.http
        .post(
          `${environment.apiUrl}/api/v1/users/session/${context.sessionId}`,
          {}
        )
        .subscribe((result: any) => {
          let r = result; // JSON.parse(result);
          if (r !== null) {
            const dt = JSON.parse(atob(r['data']));
            sessionStorage.setItem(`vehicleQuoteRes_2`, dt.vehicleQuoteRes);
            sessionStorage.setItem(`amenitiesRes_2`, dt.amenitiesRes);
            sessionStorage.setItem(`usrinfo_2`, dt.usrinfo);
            sessionStorage.setItem(`priceDetail_2`, dt.priceDetail);
            sessionStorage.setItem(`fixedPriceDetail_2`, dt.fixedPriceDetail);

            resolve(dt);
          } else {
            resolve({
              status: 'next',
            });
          }
        });
    });
  }

  loadSessionParams4() {
    return new Promise((resolve, reject) => {
      let context = {
        action: 'buslane_get_user_session',
        sessionId: sessionStorage.getItem('user_session_2') + '_checkout_2',
      };
      this.http
        .post(
          `${environment.apiUrl}/api/v1/users/session/${context.sessionId}`,
          {}
        )
        .subscribe((result: any) => {
          let r = result; // JSON.parse(result);
          if (r !== null) {
            const dt = JSON.parse(atob(r['data']));
            sessionStorage.setItem(`formData_2`, dt.formData);
            sessionStorage.setItem(`totalVehicle_2`, dt.totalVehicle);
            sessionStorage.setItem(`locationByDay_2`, dt.locationByDay);
            sessionStorage.setItem(`trip_timestamps_2`, dt.trip_timestamps);
            sessionStorage.setItem(`times_2`, dt.times);
            sessionStorage.setItem(`totalExtra_2`, dt.totalExtra);
            sessionStorage.setItem(`trip_timestamps_2`, dt.trip_timestamps);

            resolve(dt);
          } else {
            resolve({ status: 'next' });
          }
        });
    });
  }

  getQuoteByOperator(operatorId: string, quoteId: number) {
    return this.http.post<any>(`${environment.apiRoute}/quotes/verify-operator-quote/${operatorId}`, { quoteId })
  }

  generateGUID(tripId: number | string) {
    return this.http.post<any>(`${environment.apiRoute}/quote-modifications/generate-guid`, { tripId })
  }

  editQuotePrice(quoteId: number, pricePayload: any) {
    return this.http.post<any>(`${environment.apiRoute}/quotes/edit-quote-price`, { quoteId, pricePayload })
  }

  editModifiedQuotePrice(quoteId: number, pricePayload: any) {
    return this.http.post<any>(`${environment.apiRoute}/quote-modifications/edit-modified-quote-price`, { quoteId, pricePayload })
  }

  sendSingleDayQuote(quoteId: number) {
    return this.http.post<any>(`${environment.apiRoute}/quotes/send-single-quote`, { quoteId })
  }

  getInquiryStatus(body: { quoteId: number }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quote-modifications/get-quote-status`;
    return this.http.post<any>(endPoint, body, { headers });
  }

  getModifiedQuoteById(body: { id: number | string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quote-modifications/get-modified-quote`;
    return this.http.post<any>(endPoint, body, { headers });
  }

  getHistoryQuoteById(body: { id: number | string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/get-quote-history`;
    return this.http.post<any>(endPoint, body, { headers });
  }

  checkQuoteHistory(body: { quoteId: number | string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/check-for-history`;
    return this.http.post<any>(endPoint, body, { headers });  
  }

  sendQuotePriceChange(body: { payload: any }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/send-quote-price-modification`;
    return this.http.post<any>(endPoint, body, { headers });  
  }

  getQuotePriceChange(body: { quoteId: number | string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/get-quote-price-modification`;
    return this.http.post<any>(endPoint, body, { headers });  
  }

  updatePriceChange(body: { quoteId: number | string, status: string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/update-price-changed`;
    return this.http.post<any>(endPoint, body, { headers });  
  }

  updateQuoteToDeclined(body: { quoteId: number, reason: string }) {
    const userData = JSON.parse(localStorage.getItem('user_data') as string);
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${userData?.accessToken}`
    );

    const endPoint = `${environment.apiRoute}/quotes/decline-quote`;
    return this.http.post<any>(endPoint, body, { headers });  
  }

  payInitialAmount(body: { quoteId: number }) {
    const endPoint = `${environment.apiRoute}/quotes/pay-initial-quote-amount`;
    return this.http.post<any>(endPoint, body);
  }
}
