import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from '@app/pages/inventories/shared/services/vehicle.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environments/environment';
import { VehicleImagesService } from '@app/pages/vehicle-type-images/shared/vehicle-images.service';
import { NgxImageCompressService, DataUrl, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-modal',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss']
})
export class AddInventoryModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, private formBuilder: FormBuilder, private vehicleService: VehicleService,
    private _snackBar: MatSnackBar, private vehicleImagesService: VehicleImagesService, private imageCompress: NgxImageCompressService,

  ) {

    this.form = this.formBuilder.group({
      seats: [1, [Validators.required, Validators.min(1), Validators.max(100)]],
      vehicle_quantity: [1, [Validators.required, Validators.min(1), Validators.max(50)]],
      buslane_vehicle_type_id: ['', [Validators.required]],
      is_available: ["1"],
      is_hero_img: [false],
      vehicle_features: new FormArray([]),
      vehicle_img: [],
      // vehicle_price_per_mile: [],
      // vehicle_price_per_hour: [],
      // vehicle_min: [],
      // is_unknown: [false]
    });

    this.form.get('seats').valueChanges.subscribe(value => {
      if (value === 0) {
        this.form.get('seats').setValue(null, { emitEvent: false });
      }
    });
  }
  loading: boolean = false
  amount: any = '0.00'
  reason: any = ''
  name: string = ''
  availableBusLane: boolean = true
  heroImage: boolean = false
  amenityOptions: any = []
  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  typesVehicle: []
  formDataVehicle = new FormData()
  showError = ""
  currentImages: any[] = [];
  showImages: boolean = false;
  vehicleImage: any;
  currentType: any;
  operatorId: string;
  formData = new FormData();
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  typeSelected: any;
  storedImages: File[] = [];
  // readonly_quantity: boolean = false
  BUS_TYPES: any = [
    {
      id: 2,
      vehicle_type_name: 'MOTORCOACH',
    },
    {
      id: 3,
      vehicle_type_name: 'SHUTTLE',
    },
    {
      id: 4,
      vehicle_type_name: 'PARTY',
    },
    {
      id: 5,
      vehicle_type_name: 'VAN',
    },
  ];
  form: FormGroup;
  @Output() emitService = new EventEmitter();
  @ViewChild('nameInput') amountInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.amenityOptions = JSON.parse(localStorage.getItem("amenityOptions"))
    const userLS = JSON.parse(localStorage.getItem('buslane-session') as string);
    let userDetails = userLS ? userLS : null;
    this.operatorId = userDetails.operator_id;
    // this.form.get("is_unknown").valueChanges.subscribe(newValue => {
    //   if (newValue) {
    //     this.readonly_quantity = true
    //   } else {
    //     this.readonly_quantity = false
    //   }
    // })
  }
  updateTypesVehicle(event: any, item: any) {
    console.log(event, 'ev')
    console.log(item, 'item')

  }
  getAmenityTitle(amenity: any) {
    if (amenity.title.split(' ').length > 2) {
      let words = amenity.title.split(' ');
      for (let i = 1; i < words.length; i += 2) {
        words[i] = words[i] + '<br>';
      }
      return words.join(' ');
    }
    return amenity.title;
  }
  onCheckChange(event) {
    const formArray: FormArray = this.form.get('vehicle_features') as FormArray;

    /* Selected */
    if (event.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.source.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.source.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }
  async uploadImage(event: any) {
    this.selectedFile = event.target.files[0];
    this.formDataVehicle.append("vehicle_img", event.target.files[0])
    const reader = new FileReader();
    reader.onload = () => {
      this.previewUrl = reader.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
  toggleDialog(status: any) {
    if (status) {
      this.emitService.next(true);
    }

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
  onSubmit() {
    if (this.form.valid) {
      this.form.patchValue({
        vehicle_img: this.previewUrl
      })
      const is_available = this.form.get('is_available').value
      const is_hero_img = this.form.get('is_hero_img').value ? "1" : "0"
      // if (!this.previewUrl) {
      //   this.formDataVehicle.delete("vehicle_img")
      //   this.showError = "Vehicle image is required!"
      // }
      const userLS = JSON.parse(localStorage.getItem('buslane-session') as string);
      let userDetails = userLS ? userLS : null;
      const bus_name = this.BUS_TYPES.filter(i => i.id === this.form.get('buslane_vehicle_type_id').value)[0].vehicle_type_name
      this.formDataVehicle.append("vehicle_img", this.currentImages[0])
      this.formDataVehicle.append("seats", this.form.get('seats').value)
      this.formDataVehicle.append("buslane_vehicle_type_id", this.form.get('buslane_vehicle_type_id').value)
      this.formDataVehicle.append("is_available", is_available)
      this.formDataVehicle.append("is_hero_img", is_hero_img)
      this.formDataVehicle.append("vehicle_features", this.form.get('vehicle_features').value)
      this.formDataVehicle.append("operator_vehicle_type", bus_name)
      this.formDataVehicle.append("operator_id", userDetails.operator_id)
      this.formDataVehicle.append("operator_sync_id", userDetails.operator_id)
      this.formDataVehicle.append("vehicle_quantity", this.form.get('vehicle_quantity').value)
      // this.formDataVehicle.append("vehicle_price_per_mile", this.form.get('vehicle_price_per_mile').value)
      // this.formDataVehicle.append("vehicle_price_per_hour", this.form.get('vehicle_price_per_hour').value)
      // if (this.form.get('is_unknown').value) {
      //   this.formDataVehicle.append("quantity", this.form.get('is_unknown').value)
      // } else {
      //   this.formDataVehicle.append("quantity", this.form.get('quantity').value)
      // }
      this.vehicleService.addVehicle(this.formDataVehicle).subscribe(res => {
        this.openSnackBar('Successfully Add Inventory!', '');
        this.toggleDialog(true)
      })
      console.log('Form submitted!');
      console.log(this.form.value); // You can perform further actions with the form data here
    } else {
      console.log('Form invalid!');
      this.openSnackBar('Please check that you have filled out the form correctly. (Selected Type, Non-Negative Seats/Quantity)', '');
      // You can display error messages or perform other actions here
    }
  }
  decreaseSeat() {
    const currentSeat = this.form.controls.seats.value &&  parseInt(this.form.controls.seats.value);
    this.form.controls.seats.setValue(currentSeat - 1);
    this.onSelectionChange();

  }

  increaseSeat() {
    const currentSeat = this.form.controls.seats.value &&  parseInt(this.form.controls.seats.value);
    this.form.controls.seats.setValue(currentSeat + 1);
    this.onSelectionChange();
  }

  decreaseQuantity() {
    const quantity = this.form.controls.vehicle_quantity.value &&  parseInt(this.form.controls.vehicle_quantity.value);
    this.form.controls.vehicle_quantity.setValue(quantity - 1);
  }

  increaseQuantity() {
    const quantity = this.form.controls.vehicle_quantity.value &&  parseInt(this.form.controls.vehicle_quantity.value);
    this.form.controls.vehicle_quantity.setValue(quantity + 1);
  }

  validQuantity() {
    const control = this.form.controls.vehicle_quantity;
    let value = control.value;
    if (value < 0) {
      value = 0;
    } else if (value > 50) {
      value = 50;
    }
    control.setValue(value, { emitEvent: false })
  }

  validSeats() {
    const control = this.form.controls.seats;
    let value = control.value;
    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }
    control.setValue(value, { emitEvent: false })
  }

  checkDecimalPlaces(input: HTMLInputElement) {
    const match = this.amount.toString().match(/\.\d{3,}/);
    if (match) {
      const amount = parseFloat(this.amount.toString()).toFixed(2);
      this.amount = parseFloat(amount);
      input.value = amount;
    }
  }

  getVehicleImages(value) {
    this.currentType = value;
    const payload = {
      vehicleTypeName: this.currentType.name,
      vehicleTypeSeats: this.currentType.seats,
      operatorSyncId: this.operatorId,
    }

    this.vehicleImagesService.getVehicleTypeImages(payload).subscribe(async (response: any) => {
      if (response) {
          try {
              this.showImages = true;
              const imageData = JSON.parse(response.vehicleTypeImages);
              
              if (imageData.baseImages && Array.isArray(imageData.baseImages)) {
                  this.currentImages = imageData.baseImages;
                  this.storedImages = await Promise.all(
                    this.currentImages.map((url, index) => this.urlToFile(url, `image_${index}.jpg`))
                  );
              } else {
                  this.currentImages = [];
                  this.storedImages = [];
              }
          } catch (error) {
              this.currentImages = [];
              this.storedImages = [];
          }
      } else {
        this.showImages = false;
      }
    });
  }

  async urlToFile(url: string, fileName: string): Promise<File> {
    const response = await fetch(url, { mode: 'no-cors' });
    const blob = await response.blob();
    const fileType = blob.type || 'image/jpeg';
  
    return new File([blob], fileName, { type: fileType });
  }

  onSelectionChange() {
    this.formData = new FormData();
    const seats = this.form.controls.seats.value;
    const vehicleTypeId = this.form.controls.buslane_vehicle_type_id.value;
    
    // Find the selected vehicle type name
    const selectedType = this.BUS_TYPES.find(type => type.id === vehicleTypeId);
  
    if (selectedType) {
      const value = {
        seats: seats,
        name: selectedType.vehicle_type_name
      };
  
      this.getVehicleImages(value);
    }
  }

  onVehicleTypeChange(selectedId: number) {  
    const selectedType = this.BUS_TYPES.find(c => c.id === selectedId);
    
    if (selectedType) {
      this.typeSelected = {
        name: selectedType.vehicle_type_name,
        seats: this.form.controls.seats.value,
      };
      this.getVehicleImages({
        name: selectedType.vehicle_type_name,
        seats: this.form.controls.seats.value
      });
    }
  }

  // addImage() {
  //   this.fileInput.nativeElement.click();
  // }

  removeImage(image: string) {
    const index = this.currentImages.indexOf(image);
    if (index !== -1) {
      const payload = {
        vehicleTypeName: this.currentType.name,
        vehicleTypeSeats: this.currentType.seats,
        operatorSyncId: this.operatorId,
        vehicleTypeImage: this.currentImages[index],
      }
      
      this.vehicleImagesService.deleteVehicleTypeImage(payload).subscribe(() => {
        this.currentImages.splice(index, 1);
      });
    }
  }

  handleFileInput(imageFile: any) {
    const file = imageFile;

    if (imageFile) {
      let files = file;
      this.selectedFile = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (e.target !== null) {
          this.vehicleImage = e.target.result;
        }
      };

      const formData = new FormData();
      formData.append('vehicleTypeName', this.currentType.name);
      formData.append('vehicleTypeSeats', this.currentType.seats.toString());
      formData.append('operatorSyncId', this.operatorId);
      formData.append('vehicleTypeImages', file);

      this.vehicleImagesService.updateVehicleTypeImages(formData).subscribe(() => {
        this.getVehicleImages(this.typeSelected);
      });
    }
  }

  compressFile() {
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        this.imgResultBeforeCompress = image;
        console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

        this.imageCompress
          .compressFile(image, orientation, 100, 100, 600, 600)
          .then((result: DataUrl) => {
            const ext = fileName?.split('.').pop() || 'jpg'; // Default to jpg if no extension
            const newFileName = `${fileName}`;
            const file = this.dataURLtoFile(result, newFileName, ext)
            this.imgResultAfterCompress = result;
            this.handleFileInput(file)
            console.warn(
              'Size in bytes is now:',
              this.imageCompress.byteCount(result)
            );
            
          });
      });
  }

  dataURLtoFile(dataUrl: string, filename: string, ext: string): File {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  }

}
