import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    ActivatedRoute
} from '@angular/router';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { AclService } from '@shared/services/acl.service';

@Injectable({
    providedIn: 'root'
})
/**
 * ACL Guard to verify that user has correct permission to access.
 */
export class AclGuard implements CanActivate {
    constructor(
        private router: Router, 
        private route: ActivatedRoute,
        private userAuthenticationService: AuthService, 
        private aclService: AclService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const currentUser = this.userAuthenticationService.authValue;
        const routeName = next.data.name;
        if (!currentUser.roles || routeName.length === 0 || !this.aclService.can(routeName)) {
            // If not logged, redirect to login with current url.
            this.router.navigate(['/403'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}