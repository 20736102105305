<div class="h-100 flex justify-center">
  <div class="bg-white rounded-lg shadow-lg w-full lg:w-1/3 p-6">
    <div class="flex flex-col items-center justify-center py-4 px-8">
      <img
        src="assets/images/png/logo.png"
        alt="Buslane Logo"
        class="w-auto my-4"
      />
    </div>
    <div class="flex flex-col items-center p-8">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Buslane Invite Code</mat-label>
          <input
            matInput
            formControlName="invitationCode"
            type="text"
            name="invitationCode"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            type="email"
            name="email"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            type="password"
            name="password"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            formControlName="passwordConfirmation"
            type="password"
            name="passwordConfirmation"
            class="border rounded p-4 w-full"
          />
        </mat-form-field>

        <mat-checkbox formControlName="termsConditions">
          Accept Buslane's Operator Terms and Conditions
        </mat-checkbox>

        <div class="w-full mt-4">
          <button mat-flat-button color="primary" class="w-full">
            Sign Up
          </button>
        </div>

        <div class="w-full mt-8">
          <p class="text-center text-sm">
            By continuing, you agree to Buslane's Conditions of Use and Privacy
            Notice
          </p>

          <a
            mat-stroked-button
            [routerLink]="['/auth/signin']"
            class="w-full"
          >
            Already have an account? Login here
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
