import {Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/pages/operator-settings/users/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modify-confirm-modal.component.html',
  styleUrls: ['./modify-confirm-modal.component.scss']
})
export class ModifyConfirmModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  @Output() emitService = new EventEmitter();

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  toggleDialog(status: any) {
    this.emitService.next(status);
  }

}
