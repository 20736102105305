import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../shared/services/auth.service';

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  // Form login
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      invitationCode: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ],
      ],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
      termsConditions: [false, Validators.required],
      buslanesFees: [false, Validators.required],
    });
  }

  /**
   * @public
   * @name formControl
   * @description Getter for easy access to form fields
   */
  get formControl() {
    return this.form.controls;
  }

  /**
   * @public
   * @name onSubmit
   * @description Submit credential for login
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this._snackBar.open('Please check your input', 'close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });

      return;
    }

    if (
      !(
        this.formControl.password.value ===
        this.formControl.passwordConfirmation.value
      )
    ) {
      this._snackBar.open('Passwords do not match', 'close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });

      return;
    }


    if (
      !(
        this.formControl.termsConditions.value
      )
    ) {
      this._snackBar.open('Please accept the term and condition', 'close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });

      return;
    }

    this.authService
      .signup(
        this.formControl.email.value,
        this.formControl.password.value,
        this.formControl.invitationCode.value
      )
      .subscribe(
        (data) => {
          this._snackBar.open('Successfully registered', 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1000);
        },
        (error) => {
          this._snackBar.open('Invalid credentials or invite code', 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
          this.loading = false;
        }
      );
  }
}
