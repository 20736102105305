import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointTitle'
})
export class PointTitlePipe implements PipeTransform {

  transform(name: String): unknown {

    const regex = `(pickup_address.*)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex2 = `(destination_address_id.*)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex3 = `(dropoff_address.*)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex4 = `(stop-\\d+-address)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex5 = `(extra-stop-\\d+-address)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex6 = `(pickup-time)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex7 = `(pickup-date)`;
    const regex8 = `(destination_address.*)`;
    const regex9 = `(stop_time_\\d+)`;
    const regex10 = `(stop-\\d+-date)`; //`^(.*)(_.*?)(_)\\d*$`;
    const regex11 = `(dropoff-time)`;
    const regex12 = `(dropoff-date)`;
    const regex13 = `(stop-return-\\d+-address)`;
    const regex14 = `(stop-return-\\d+-date)`;
    const regex15 = `(stop-return-\\d+-time)`;
    const regex16 = `(new_start_address)`;


    if (name.match(regex)) {
      return `Pickup address`;
    }

    if (name.match(regex2)) {
      if (name.match(`(destination_address_id_1)`)) {
        return "Pickup address";
      }

      return `Destination`;
    }


    if (name.match(regex4)) {
      let stop = name.split('-')[1];
      return `Stop ${stop}`;

    }

    if (name.match(regex13)) {
      let stop = name.split(`-`)[2];
      return `Stop ${stop}`;
    }


    if (name.match(regex3)) {
      return `Drop off`;
    }

    if (name.match(regex16)) {
      return "Pickup address"
    }

    return "clear";
  }

}
