<mat-dialog-content class="mat-typography text-center" >
  <div class="relative p-5 overflow-hidden">
    <h2 class="leading-normal" style="font-size: 12px;">{{ data.message }}</h2>
    <h2 class="leading-normal" style="font-size: 12px;" *ngIf="!data.isChargePopup">Max Refundable Amount: {{ data.maxRefundableAmount }}</h2>
    <input style="font-size: 12px;" type="number" placeholder="Enter Amount" [(ngModel)]="amount" (input)="checkDecimalPlaces(amountInput)" #amountInput/>
    <span style="color: red; text-align: left" *ngIf="amount > data.maxRefundableAmount">{{amount}} is more than maximum refundable amount</span>
    <textarea placeholder="Enter Reason" [(ngModel)]="reason" style="font-size: 12px;"></textarea>
    <div *ngIf="amount !== '0.00'">
      <p style="font-weight: bold; font-size: 18px; margin-top: 10px;">Booking Fee: ${{ bookingFee.toFixed(2) }}</p>
      <p style="font-weight: bold; font-size: 18px;">Finance Fee: ${{ financeFee.toFixed(2) }}</p>
      <p  style="font-weight: bold; font-size: 18px;">Total: ${{ totalFee.toFixed(2) }}</p>
    </div>
   
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="toggleDialog(false)">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" [disabled]="!reason || !amount || amount == 0 || amount === '0.00' || amount > data.maxRefundableAmount" (click)="toggleDialog(true)">Continue</button>
</mat-dialog-actions>
