import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './amount-modal.component.html',
  styleUrls: ['./amount-modal.component.scss']
})
export class AmountModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  amount: any = '0.00'
  reason: any = ''
  bookingFee: number = 0
  financeFee: number = 0
  totalFee: number = 0
  @Output() emitService = new EventEmitter();
  @ViewChild('amountInput') amountInput: ElementRef<HTMLInputElement>;
  ngOnInit(): void {
  }

  toggleDialog(status: any) {
    if (status) {
      let payload = {
        amount: Number(this.amount),
        reason: this.reason,
        totalFee: Number(this.totalFee).toFixed(2)
      }

      this.emitService.next(payload);
    }

  }

  checkDecimalPlaces(input: HTMLInputElement) {
    const match = this.amount.toString().match(/\.\d{3,}/);
    if (match) {
      const amount = parseFloat(this.amount.toString()).toFixed(2);
      this.amount = parseFloat(amount);
      input.value = amount;
    }
    this.bookingFee = Number(this.amount) * 5 / 100
    if (this.data.paymentType === 'cc') {
      this.financeFee = (Number(this.amount) + this.bookingFee) * 3.25 / 100
    } else {
      this.financeFee = 0
    }
    this.totalFee = this.bookingFee + this.financeFee + Number(this.amount)
  }

}
