import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '@shared/services/acl.service';

@Directive({
    selector: '[hasRole]'
})
export class HasRoleDirective {
    // roles: string[];
    access: string;

    constructor(
        private aclService: AclService,
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    @Input()
    set hasRole(val) {
        // this.roles = Array.isArray(val) ? val : [val];
        this.access = val
        this.updateView();
    }

    /**
     * Update view to keep or delete current element.
     */
    private updateView() {
        if (this.aclService.can(this.access)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}