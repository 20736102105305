<mat-dialog-content>
  <p>{{ message }}</p>

  <!-- Dropdown menu for decline reasons -->
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Select a Reason</mat-label>
    <mat-select [(value)]="selectedReason" required>
      <mat-option *ngFor="let reason of reasons" [value]="reason">
        {{ reason }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Text area for "Other" reason -->
  <mat-form-field *ngIf="showOtherReason()" appearance="fill" class="full-width">
    <mat-label>Enter Other Reason</mat-label>
    <textarea matInput [(ngModel)]="otherReason" rows="4" required></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <!-- Cancel button -->
  <button mat-raised-button mat-dialog-close tabindex="-1">
    {{ cancelButtonText }}
  </button>
  <!-- Submit button -->
  <button
    mat-raised-button
    color="primary"
    [disabled]="!selectedReason || (showOtherReason() && !otherReason)"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
