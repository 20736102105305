import { Component, OnInit } from '@angular/core';
import { StripeService } from '@app/pages/stripe/stripe.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css'],
})
export class StripeComponent implements OnInit {
  constructor(
    private readonly stripeService: StripeService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.stripeService
        .createStripeAccount(
          params.code,
          JSON.parse(localStorage.getItem('buslane-session')).operator_id
        )
        .subscribe(
          (res) => {
            this.router.navigate(['operator-settings/banking-information']);
          },
          (error) => {
            this.router.navigate(['operator-settings/banking-information']);
          }
        );
    });
  }
}
