import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authService.authValue
        if (user) {
            if (route.data?.name == 'auth') {
                this.router.navigate(['/dashboard'])
            }
            // authorised so return true
            return true
        }

        if (!user && route.data?.name != 'auth') {
            this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: state.url } })
            return false
        }

        return true
    }
}
