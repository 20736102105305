import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ModalComponent } from './components/modal/modal.component';
import { FeatureIconsPipe } from '@app/shared/utils/feature-icons.pipe';
import { SafePipe } from './utils/safe.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { DialogBookingComponent } from './components/dialog-booking/dialog-booking.component';
import { PointTitlePipe } from './utils/point-title.pipe';
import { AmountModalComponent } from './components/amount-modal/amount-modal.component';
import { RefundModalComponent } from './components/refund-modal/refund-modal.component';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';
import { AddInventoryModalComponent } from './components/add-inventory/add-inventory.component';
import { MatRadioModule } from '@angular/material/radio';
import { ModifyConfirmModalComponent } from './components/modify-confirm/modify-confirm-modal.component';
import { DeclineBookingComponent } from './components/decline-quote/decline-quote.component';
import { PayInitialAmountModalComponent } from './components/pay-initial-amount/pay-initial-amount-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    AmountModalComponent,
    UploadModalComponent,
    FeatureIconsPipe,
    SafePipe,
    DialogBookingComponent,
    PointTitlePipe,
    RefundModalComponent,
    AddInventoryModalComponent,
    ModifyConfirmModalComponent,
    DeclineBookingComponent,
    PayInitialAmountModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatFileUploadModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    NgxMaskModule.forRoot(),
    MatRadioModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatFileUploadModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    ModalComponent,
    FeatureIconsPipe,
    SafePipe,
    PointTitlePipe
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class SharedModule { }
