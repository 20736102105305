import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { IAuth } from '@app/pages/auth/shared/interfaces/auth.interface';
import { UploadModalComponent } from '@app/shared/components/upload-modal/upload-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TalkService } from '@app/shared/services/talkjs.service';
import { NavigationStart, Router } from '@angular/router';
import Talk from 'talkjs';
import { filter, takeUntil } from 'rxjs/operators';
// import { environment } from '@environments/environment';
// import { SocketService } from '@app/shared/services/socket.service';

export interface NavItem {
  id: string;
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  currentUser: Observable<IAuth>;
  openModalUpdateLogo: boolean = false;
  imageLogo: string = '';
  unreadMessage: number = 0;
  private inbox: Talk.Popup;

  menu: NavItem[] = [
    {
      id: 'dashboard',
      displayName: 'Dashboard',
      iconName: '',
      route: '/dashboard',
    },
    {
      id: 'inventories',
      displayName: 'Inventory',
      iconName: '',
      children: [
        {
          id: 'vehicleFleet',
          displayName: 'Vehicle Fleet',
          iconName: '',
          route: '/inventories',
        },
        {
          id: 'vehicleTypeImages',
          displayName: 'Vehicle Images',
          iconName: '',
          route: '/vehicle-images'
        },
        {
          id: 'pricing',
          displayName: 'Pricing',
          iconName: '',
          route: '/pricing',
        },
      ]
    },

    {
      id: 'messaging',
      displayName: 'Messaging',
      iconName: '',
      route: '/message',
    },
    {
      id: 'operatorSettings',
      displayName: 'Operator Settings',
      iconName: '',
      children: [
        // BM-526: Operator - Remove My Profile from menu
        // {
        //   id: 'myProfile',
        //   displayName: 'My Profile',
        //   iconName: '',
        //   route: '/operator-settings/my-profile'
        // },
        {
          id: 'profiles',
          displayName: 'Operator Profile',
          iconName: '',
          route: '/operator-settings/profiles',
        },
        {
          id: 'companyInformation',
          displayName: 'Company Information',
          iconName: '',
          route: '/operator-settings/company-information',
        },
        {
          id: 'bankingInformation',
          displayName: 'Banking Information',
          iconName: '',
          route: '/operator-settings/banking-information',
        },
        {
          id: 'users',
          displayName: 'Users',
          iconName: '',
          route: '/operator-settings/users',
        },
      ]
    },
    {
      id: 'userSettings',
      displayName: 'User Settings',
      iconName: '',
      children: [
        {
          id: 'userProfile',
          displayName: 'User Profile',
          iconName: '',
          route: '/user-settings/user-profile'
        },
        {
          id: 'changeUserPassword',
          displayName: 'Change Password',
          iconName: '',
          route: '/user-settings/change-password'
        }
      ]
    },
  ];
  mobileQuery: MediaQueryList;
  userInfo: any;
  intervalId: any;
  private _mobileQueryListener: () => void;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private dialog: MatDialog,
    private talkService: TalkService,
    private router: Router
  ) // private socketService: SocketService
  {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  async ngOnInit() {
    // this.socketService.connectToSocket()
    // this.startInterval();
    const userLS = JSON.parse(
      localStorage.getItem('buslane-session') as string
    );
    let userDetails = userLS ? userLS : null;
    this.userInfo = userDetails;
    this.currentUser = this.authService.auth;
    this.imageLogo =
      userDetails.img_profile || 'https://dummyimage.com/200x100/000/fff';
    this.talkService
      .getUnreadMessage(userDetails.id)
      .subscribe((res: { unread_messages: number }) => {
        this.unreadMessage = res.unread_messages;
      });
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe((e) => {
        this.talkService
          .getUnreadMessage(userDetails.id)
          .subscribe((res: { unread_messages: number }) => {
            this.unreadMessage = res.unread_messages;
          });
      });
    // this.createInbox()
  }
  // startInterval() {
  //   this.intervalId = setInterval(() => {
  //     this.socketService.emit("keep-connection")
  //     console.log('xxx')
  //   }, 60000);
  // }
  private createInbox() {
    const userLS = JSON.parse(
      localStorage.getItem('buslane-session') as string
    );

    this.talkService
      .getListAdminUser()
      .subscribe(async (listOperatorUser: any) => {
        const session = await this.talkService.createCurrentSession();
        this.inbox = await this.talkService.createPopup(
          session,
          listOperatorUser
        );
        const listUserOnDuty =
          listOperatorUser.filter((i: any) => i.duty_status) || [];
        if (listUserOnDuty.length === 0) {
          const payload = {
            message: 'We are currently offline. Please leave a message.',
            conversationId: `conversations_${userLS.email}`,
          };
          this.talkService.sendSystemMessage(payload).subscribe();
        }
      });
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    clearInterval(this.intervalId);
  }


  onLogout() {
    this.authService.logout();
  }
  openModalUpdate() {
    this.dialog
      .open(UploadModalComponent)
      .componentInstance.emitService.subscribe((payload: any) => {
        let sessionData = localStorage.getItem('buslane-session')
        let sessionObject = JSON.parse(sessionData);
        sessionObject.img_profile = payload.img_profile
        let updatedSessionData = JSON.stringify(sessionObject);
        localStorage.setItem('buslane-session', updatedSessionData);
        this.imageLogo = payload.img_profile;
      });
  }
}
