import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  END_POINT = 'operator/create-stripe-account';

  constructor(private readonly http: HttpClient) {}

  createStripeAccount(
    authorizationCode: string,
    operatorId: string
  ): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${environment.apiRoute}/${this.END_POINT}`,
      {
        authorizationCode,
        operatorId,
      }
    );
  }
}
