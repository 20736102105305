import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VehicleImagesService {
  constructor(
    private http: HttpClient,
    private userAuthentationService: AuthService
  ) {}

  updateVehicleTypeImages(payload: any) {
    return this.http.post<any>(`${this.getUrl()}/update-vehicle-type-images`, payload, {})
  }

  getVehicleTypeImages(payload: any) {
    return this.http.post<any>(`${this.getUrl()}/get-vehicle-type-images`, payload, {})
  }

  deleteVehicleTypeImage(payload: any) {
    return this.http.post<any>(`${this.getUrl()}/delete-vehicle-type-image`, payload, {})
  }

  private getUrl() {
    return `${environment.apiRoute}/vehicles`
  }
}
