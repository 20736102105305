import {
  Component,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './pay-initial-amount-modal.component.html',
  styleUrls: ['./pay-initial-amount-modal.component.scss'],
})
export class PayInitialAmountModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  )
   { }
  amount: any = '0.00';
  reason: any = '';
  bookingFee: number = 0;
  financeFee: number = 0;
  totalFee: number = 0;
  totalAmountPaid: number = 0;
  @Output() emitService = new EventEmitter();
  @ViewChild('amountInput') amountInput: ElementRef<HTMLInputElement>;
  ngOnInit(): void {
    this.data.paymentDetails.forEach((element: any) => {
            if ((element.parentPaymentId === 1 || element.parentPaymentId === 2) && (element.chargeStatus != 'pending' || element.chargeStatus != 'failed')) {
        this.totalAmountPaid = this.totalAmountPaid + element.paymentAmount;
      }
    });
    // const initialPayment = this.data?.paymentDetails.find(
    //   (payment: {
    //     parentPaymentId: number;
    //     paymentId: number;
    //   }) => {
    //     if (payment.parentPaymentId === 1) {
    //       const isReferenced = this.data?.paymentDetails.some(
    //         (otherPayment: { parentPaymentId: any }) =>
    //           otherPayment.parentPaymentId ===
    //           payment.paymentId
    //       );
    //       return !isReferenced;
    //     }
    //     return false;
    //   }
    // )
    // console.log(initialPayment);
  }

  toggleDialog(status: any) {
    if (status) {
      let payload = {
        amount: Number(this.amount),
        totalFee: Number(this.totalFee).toFixed(2),
      };

      this.emitService.next(payload);
    }
  }

  checkDecimalPlaces(input: HTMLInputElement) {
    const match = this.amount.toString().match(/\.\d{3,}/);
    if (match) {
      const amount = parseFloat(this.amount.toString()).toFixed(2);
      this.amount = parseFloat(amount);
      input.value = amount;
    }
    this.bookingFee = (Number(this.amount) * 5) / 100;
    if (this.data.quoteData.quote.paymentMethod === 'cc') {
      this.financeFee = ((Number(this.amount) + this.bookingFee) * 3.25) / 100;
    } else {
      this.financeFee = 0;
    }
    this.totalFee = this.bookingFee + this.financeFee + Number(this.amount);
  }
  
}
