<div class="h-screen flex items-center justify-center">
  <div class="bg-white rounded-lg shadow-lg w-full lg:w-1/3 p-6">
    <div class="flex flex-col items-center justify-center p-8">
      <img
        src="assets/images/png/logo.png"
        alt="Buslane Logo"
        class="w-auto my-4"
      />
      <span class="font-medium text-center text-3xl my-4"
        >Reset my password</span
      >
      <div class="d-flex-center">
        <form class="modal-form" [formGroup]="resetForm">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width ffw"
          >
            <p class="point__label">Code</p>
            <div class="input-container">
              <input
                matInput
                placeholder="Enter your code"
                formControlName="code"
                aria-label="State"
                type="text"
                onkeypress="return event.charCode !== 13"
              />
            </div>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width ffw"
          >
            <p class="point__label">Password</p>
            <div class="input-container">
              <input
                matInput
                placeholder="Enter your password"
                formControlName="password"
                aria-label="State"
                type="password"
                onkeypress="return event.charCode !== 13"
              />
            </div>
          </mat-form-field>
          <div
            class="text-danger error password m9"
            *ngIf="resetForm.touched && resetForm.get('password')?.errors?.weak"
          >
            Please provide a password with the following rule:<br />
            <ul>
              <li>At least 7 characters</li>
              <li>At least one letter</li>
              <li>At least one number</li>
            </ul>
          </div>
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width ffw"
          >
            <p class="point__label">Confirm Password</p>
            <div class="input-container">
              <input
                matInput
                placeholder="Confirm your password"
                formControlName="confirmPassword"
                aria-label="State"
                type="password"
                onkeypress="return event.charCode !== 13"
              />
            </div>
          </mat-form-field>
          <div
            class="text-danger error b22 password m9"
            *ngIf="resetForm.get('confirmPassword')?.touched && resetForm.get('confirmPassword')?.errors?.mismatch"
          >
            Passwords do not match.
          </div>
          <br />
          <div class="w-full mt-3">
            <button
              mat-flat-button
              color="primary"
              class="w-full"
              (click)="handleReset()"
            >
              Reset My Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
