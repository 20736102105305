import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,

  ) { }
  selectedFile: any
  profileImage: any = null
  placeHolder: string = "https://media.istockphoto.com/id/1214428300/vector/default-profile-picture-avatar-photo-placeholder-vector-illustration.jpg?s=612x612&w=0&k=20&c=vftMdLhldDx9houN4V-g3C9k0xl6YeBcoB_Rk6Trce0="

  @Output() emitService = new EventEmitter();
  @ViewChild('amountInput') amountInput: ElementRef<HTMLInputElement>;
  ngOnInit(): void {
    const userLS = JSON.parse(localStorage.getItem('buslane-session') as string);
    let userDetails = userLS ? userLS : null;
    this.profileImage = userDetails.img_profile || this.placeHolder
  }

  toggleDialog(status: any) {
    if (status) {
      this.updateProfileImage()
    }

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
  updateProfileImage() {
    if (this.selectedFile) {
      let formData = new FormData();
      formData.append('operator_img', this.selectedFile);
      this.authService.updateUserProfile(formData).subscribe(
        (results: any) => {
          this.emitService.next(results);
          this.openSnackBar('Your Profile Image has been updated.', '');
        },
        (err) => {
          this.openSnackBar('An error occurred !', '');
        }
      );
    } else {
      this.openSnackBar('Choose an image first', '');
    }
  }
  handleFileInput(event: any) {
    if (event) {
      let files = event.target.files;
      this.selectedFile = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        if (e.target !== null) {
          this.profileImage = e.target.result;
        }
      };
    }
  }
}
