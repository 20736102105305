<mat-dialog-content class="mat-typography text-center">
  <h1>Details</h1>
  <div class="relative p-5 overflow-hidden">
    <div class="absolute right-0 top-0" *ngIf="data.showNote">
      <a href="#" class="text-gray-400 hover:text-gray-600"
        ><mat-icon aria-hidden="false" aria-label="Example home icon"
          >information</mat-icon
        ></a
      >
    </div>
    <h2 class="leading-normal">Charge Reason: {{ data.message }}</h2>
    <h2 class="leading-normal">Base Charge: ${{ data.baseCharge.toFixed(2) }}</h2>
    <h2 class="leading-normal">Booking Fee: ${{ data.bookingFee.toFixed(2) }}</h2>
    <h2 class="leading-normal">Finance Fee: ${{ data.financeFee.toFixed(2) }}</h2>
    <h2 class="leading-normal">Total ${{ total().toFixed(2) }}</h2>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data.showButton">
  <button
    mat-raised-button
    mat-dialog-close
    color="primary"
    (click)="toggleDialog()"
    style="color: white"
  >
    OK
  </button>
</mat-dialog-actions>
