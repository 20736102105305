import {Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  @Output() emitService = new EventEmitter();

  ngOnInit(): void {
  }

  toggleDialog(status: any) {
    this.emitService.next(status);
  }

}
