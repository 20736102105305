<div id="admin-layout">
  <mat-toolbar
    color="primary"
    fxHide.gt-xs
    class="admin-toolbar fixed-topnav mat-elevation-z1 flex justify-between"
  >
    <div class="lg:hidden">
      <button mat-icon-button id="menu" (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <img src="assets/images/png/logo_white.png" alt="Buslane Logo" />
    <div class="flex items-center p-4">
      <button
        class="text-sm flex items-center"
        [matMenuTriggerFor]="headMenu"
        *ngIf="userInfo"
      >
        <span class="mr-2">{{ userInfo.first_name }} {{ userInfo.last_name }}</span>
        <mat-icon aria-hidden="false" aria-label="Example icon">
          arrow_drop_down
        </mat-icon>
      </button>
      <mat-menu #headMenu="matMenu">
        <button mat-menu-item (click)="onLogout()">
          <span>Log Out</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      opened="true"
      [disableClose]="mobileQuery.matches ? false : true"
    >
      <mat-nav-list>
        <div class="sidebar-logo flex justify-center p-4">
          <div
            class="sidebar-logo-container cursor-pointer"
            (click)="openModalUpdate()"
          >
            <img [src]="imageLogo" alt="Operator Logo" />
          </div>
        </div>
        <span *ngFor="let item of menu">
          <span *hasRole="item.id">
            <span *ngIf="item.children && item.children.length > 0">
              <mat-accordion [displayMode]="flat">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="flex items-center space-x-4">
                        <mat-icon *ngIf="item.iconName">
                          {{ item.iconName }}
                        </mat-icon>
                        <span>{{ item.displayName }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span *ngFor="let child of item.children">
                    <span *hasRole="child.id">
                      <mat-list-item [routerLink]="[child.route]">
                        <div class="flex items-center space-x-4">
                          <mat-icon *ngIf="child.iconName">
                            {{ child.iconName }}
                          </mat-icon>
                          <span>{{ child.displayName }}</span>
                        </div>
                      </mat-list-item>
                    </span>
                  </span>
                </mat-expansion-panel>
              </mat-accordion>
            </span>
          </span>

          <span *hasRole="item.id">
            <span *ngIf="!item.children || item.children.length === 0">
              <mat-list-item [routerLink]="[item.route]">
                <div class="flex items-center space-x-4">
                  <mat-icon *ngIf="item.iconName">{{ item.iconName }}</mat-icon>
                  <span>{{ item.displayName }}</span>
                    <div class="unread-qty"
                    *ngIf="item.displayName === 'Messaging'">
                    <div class="bubble">
                      {{ unreadMessage }}
                    </div>
                  </div>
                </div>
              </mat-list-item>
            </span>
          </span>
        </span>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="min-h-screen">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
