// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google_maps_key: 'AIzaSyB9PC-QkIrgcsJBX9M5OBhnCCOpw2JDhcc',
  uploadUrl: 'https://buslane-upload-storage-sqdyjvm7uq-uc.a.run.app',
  // env local
  // apiUrl: 'http://localhost:5000',
  apiUrl: 'https://dev-buslane-python-api-gc656ktdia-uc.a.run.app',
  apiPaymentUrl: 'http://stripe.buslane.com',
  // apiRoute: 'http://localhost:3000',
  apiRoute: 'https://booking-engine-api-node-gc656ktdia-uc.a.run.app',
  operatorID: '9aed9dfd-6fa9-4b86-90ef-a1f5692d7df2',
  APP_ID: 'tN3Z80AL',
  APP_SECRET: 'sk_test_GSq32meu9FsfBDnsxo1hNUxZdy5cMln4',
  STRIPE_ID: 'ca_JgDnVGOm6oYrZ0X4jR0ZbELjkZSq3WKN',
  OP_URL: 'https://buslane-dev.uc.r.appspot.com',
  // MP_URL: 'http://localhost:4200',
  // WL_URL: 'http://localhost:4200',
  MP_URL: 'https://buslane-booking-engine-marketplace-dot-buslane-dev.uc.r.appspot.com',
  WL_URL: 'https://buslane-booking-engine-demo-beelinetours-dot-buslane-dev.uc.r.appspot.com',
  conversationIDSuffix: '_UAT'
  // env dev
  // apiUrl: 'https://dev-buslane-python-api-gc656ktdia-uc.a.run.app/',
  // apiPaymentUrl: 'https://dev-buslane-node-api-gc656ktdia-uc.a.run.app',
  // env prod
  // apiUrl: "https://dev-python-api-sqdyjvm7uq-uc.a.run.app",
  // apiPaymentUrl: "https://dev-node-api-sqdyjvm7uq-uc.a.run.app"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
