import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'featureIcons',
})
export class FeatureIconsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string): SafeHtml {
    let html = '';
    if (value.toLowerCase() == 'wifi') {
      html += `
      <div class="icon-feature">
        <iclass="fa fa-wifi"></i>
        <div class="icon-gp-subhead">Wifi</div>
      </div>`;
    }
    if (value.toLowerCase() == 'charging') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-bolt"></i>
        <div class="icon-gp-subhead">Charging</div>
      </div>`;
    }
    if (value.toLowerCase() == 'tv') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-tv"></i>
        <div class="icon-gp-subhead">Tv</div>
      </div>`;
    }
    if (value.toLowerCase() == 'bathroom') {
      html += `
      <div class="icon-feature">
          <div class="d-flex align-items-center">
            <i class="fa fa-male"></i> | <i class="fa fa-female"></i>
          </div>
          <div class="icon-gp-subhead">Bathroom</div>
      </div>`;
    }
    if (value.toLowerCase() == 'acme') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-lock"></i>
        <div class="icon-gp-subhead">Acme</div>
      </div>`;
    }
    if (value.toLowerCase() == 'pa system') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-volume-up"></i>
        <div class="icon-gp-subhead">PA System</div>
      </div>`;
    }
    if (value.toLowerCase() == 'gps') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-location-arrow"></i>
        <div class="icon-gp-subhead">GPS</div>
      </div>`;
    }
    if (value.toLowerCase() == 'ada accessable') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-wheelchair"></i>
        <div class="icon-gp-subhead">ADA Accessable</div>
      </div>`;
    }
    if (value.toLowerCase() == 'dvd') {
      html += `
      <div class="icon-feature">
        <i class="fa fa-play-circle"></i>
        <div class="icon-gp-subhead">DVD</div>
      </div>`;
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
