<mat-dialog-content class="mat-typography text-center">
  <div class="relative p-5 overflow-hidden">
    <div class="absolute right-0 top-0" *ngIf="!data.showInfo">
      <a href="#" class="text-gray-400 hover:text-gray-600"><mat-icon aria-hidden="false" aria-label="Example home icon">information</mat-icon></a>
    </div>
    <h2 class="leading-normal">{{ data.message }}</h2>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close (click)="toggleDialog(false)">No</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="toggleDialog(true)">Yes</button>
</mat-dialog-actions>
